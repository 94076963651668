import React from 'react';
import { Box, Button, Hidden, Theme, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../../modules/auth/auth-hook';
type HeaderAuthProps = {
  isInvited?: boolean;
};
const useStyles = makeStyles<Theme>(() =>
  createStyles({
    logoClasses: {
      position: 'absolute',
      top: '0',
      left: '0',
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
    button: {
      color: 'white!important',
      width: '150px',
      height: '38px !important',
      border: 'none !important',
    },
    invited: {
      height: '100%',
      component: 'div',
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      width: '100%',
    },
  }),
);
export const HeaderAuth: React.FC<HeaderAuthProps> = ({ isInvited }) => {
  const classes = useStyles();
  const { user } = useAuth();
  const navigate = useNavigate();
  return (
    <Box
      style={{ background: 'black' }}
      height={'75px'}
      justifyContent={isInvited ? 'end' : 'center'}
      alignItems={'center'}
      display={'flex'}
      position={'relative'}
      paddingX={'30px'}
    >
      <Box
        className={isInvited && !user ? classes.invited : classes.logoClasses}
      >
        <a href="https://animalpolitico.com">
          <img
            src="/logo.svg"
            alt="logo"
            width={130}
            height={40}
            style={{ flexGrow: 1, objectFit: 'contain' }}
          />
        </a>
      </Box>

      {isInvited && (
        <>
          {!user && (
            <Box display={'flex'} alignItems={'center'}>
              <Hidden mdUp={false} mdDown={true}>
                <Typography noWrap style={{ color: 'white', marginRight: 15 }}>
                  ¿YA ERES SUSCRIPTOR?
                </Typography>
              </Hidden>
              <Button
                className={classes.button}
                variant="contained"
                color="primary"
                disableElevation
                style={{
                  textTransform: 'capitalize',
                  margin: 'none !important',
                  border: 'none !important',
                }}
                onClick={() => navigate('/autenticacion')}
              >
                <Typography>Inicia Sesión</Typography>
              </Button>
            </Box>
          )}
        </>
      )}
    </Box>
  );
};
