import {
  PlanFrequency,
  SubscriptionStatuses,
  //SubscriptionStatuses,
  User,
  UserRoles,
} from '../../shared/types/generated';
import { RBACSchema } from './rbac-permissions';
import { RBACPermissions } from './rbac-types';
import { GUEST_ROLE } from '../../shared/constans';

export const getHighestRole = (user: User | undefined | null): string => {
  const totalRoles = user?.roles_on_users?.length ?? 0;
  let role = GUEST_ROLE;
  for (let i = 0; i < totalRoles; i++) {
    if (user?.roles_on_users?.[i]?.role?.name === UserRoles.Administrator) {
      role = UserRoles.Administrator;
      break;
    } else if (user?.roles_on_users?.[i]?.role?.name === UserRoles.Suscriptor) {
      role = UserRoles.Suscriptor;
      break;
    } else if (user?.roles_on_users?.[i]?.role?.name === UserRoles.User) {
      role = UserRoles.User;
    }
  }
  return role;
};

export const isAnnualSubscriptor = (user: User | undefined | null): boolean => {
  const roleName = getHighestRole(user);
  const subscription =
    roleName === UserRoles.Suscriptor || roleName === UserRoles.Administrator;

  if (subscription) {
    const isAnnualSubs = user?.subscriptions?.some(
      (subs) =>
        subs?.status === SubscriptionStatuses.Active &&
        subs.plan?.frequency === PlanFrequency.Year,
    );
    return isAnnualSubs || false;
  }
  return false;
};

export const checkUserRole = (
  user: User | undefined | null,
  permission: RBACPermissions,
): boolean => {
  const roleName = getHighestRole(user);
  const annualSubscription = isAnnualSubscriptor(user);
  const subscription =
    roleName === UserRoles.Suscriptor || roleName === UserRoles.Administrator;
  //console.log({ roleName, subscription, permission });

  if (permission === RBACPermissions.CAN_SUBSCRIPTION && subscription)
    return false;

  if (permission === RBACPermissions.CAN_ANNUAL_SUBSCRIPTION) {
    return !annualSubscription;
  }

  return !!RBACSchema.find(
    (schema) =>
      schema.Role === roleName && schema.Permissions.includes(permission),
  );
};
